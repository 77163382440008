<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：发货通知书</strong>
                                    <el-checkbox v-if="isMobile" style="padding-left: 25px" v-model="showFrom">显示表单</el-checkbox>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row v-show="showFrom">
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" content="单位(客户)" placement="bottom-end" :open-delay = "750">
                                    <el-form-item label="客户:">
                                        <el-select ref="customer" v-model="formData.customer" style="width: 100%"
                                                   @keyup.native.enter="blurNext('customer'),focusNext('shipper')"
                                                   @change="isDataChanage=true"
                                                   default-first-option remote :remote-method="$_searchCustomerList"
                                                   @visible-change="(v) => $refs.AddCompany.visibleChange(v, this.$refs['customer'])"
                                                   clearable
                                                   filterable size="mini">
                                            <el-option
                                                    v-for="item in $store.getters.getCustomerList"
                                                    :key="item.id"
                                                    :label="item.customer_name"
                                                    :value="item.customer_name">
                                                <span style="float: left">{{item.customer_name}}</span>
                                                <span v-if="$_getActionKeyListBoolean(['/customers/editVue'])" style="float: right"><i class="el-icon-edit-outline__close el-icon-edit-outline" @click.stop="$_blurNext('customer'),$refs.AddCompany.editRowEvent(item.id)"/></span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <AddCompany ref="AddCompany"></AddCompany>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="日期:">
                                    <el-date-picker
                                            v-model="formData.bill_date_fh"
                                            ref="bill_date_fh"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            @change="focusNext('shipper'),isDataChanage=true"
                                            @keyup.native.enter="focusNext('shipper')"
                                            :clearable="false"
                                            style="width: 130px">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="发货单-单号:" label-width="130px">
                                    <el-input ref="bill_id_fh" v-model="formData.bill_id_fh" :readonly="true"
                                              placeholder="可生成"
                                              @keyup.native.enter="focusNext('bill_id_kdtzs')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="开单通知书-单号:" label-width="130px">
                                    <el-input ref="bill_id_kdtzs" v-model="formData.bill_id_kdtzs" :readonly="true"
                                              placeholder="未关联"
                                              @keyup.native.enter="focusNext('bill_id_kd')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="销售开单-单号:" label-width="130px">
                                    <el-input ref="bill_id_kd" v-model="formData.bill_id_kd" :readonly="true"
                                              placeholder="未关联"
                                              @keyup.native.enter="focusNext('shipper')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="发货人:">
                                    <el-select ref="shipper" v-model="formData.shipper" clearable placeholder="可搜索查询"
                                               size="mini" @change="isDataChanage=true"
                                               default-first-option
                                               @focus="$_salesManListFilter"
                                               :filter-method="$_salesManListFilter"
                                               @keyup.native.enter="blurNext('shipper'),focusNext('reviewer')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getAccountList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="复核人:">
                                    <el-select ref="reviewer" v-model="formData.reviewer" clearable placeholder="可搜索查询"
                                               size="mini" @change="isDataChanage=true"
                                               default-first-option
                                               @focus="$_salesManListFilter"
                                               :filter-method="$_salesManListFilter"
                                               @keyup.native.enter="blurNext('reviewer'),focusNext('customer_phone')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getAccountList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="司机:" label-width="80px">
                                    <el-input ref="customer_phone" v-model="formData.customer_phone"
                                              @change="isDataChanage=true"
                                              @keyup.native.enter="focusNext('car_code')"
                                              placeholder="请输入" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="车号:">
                                    <el-input ref="car_code" v-model="formData.car_code"
                                              @change="isDataChanage=true"
                                              @keyup.native.enter="focusNext('remark_warehouse_bill')"
                                              placeholder="请输入" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-form-item label="备注:">
                                    <el-input ref="remark_warehouse_bill" v-model="formData.remark_warehouse_bill"
                                              @change="isDataChanage=true"
                                              @keyup.native.enter="$_blurNext('remark_warehouse_bill'),$_focusNext('sales_man')"
                                              placeholder="请输入" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="业务员:">
                                    <el-select ref="sales_man" v-model="formData.sales_man" clearable placeholder="可搜索查询"
                                               size="mini"
                                               @change="isDataChanage=true"
                                               @focus="$_salesManListFilter"
                                               :filter-method="$_salesManListFilter"
                                               default-first-option
                                               @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getSalesManList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        修改人:{{formData.last_name_warehouse_bill ? formData.last_name_warehouse_bill : ''}}<br>
                                        修改时间:{{formData.last_date_warehouse_bill ? formData.last_date_warehouse_bill : ''}}<br>
                                        创建时间:{{formData.creat_date_warehouse_bill ? formData.creat_date_warehouse_bill : ''}}
                                    </div>
                                    <el-form-item label="记录员:">
                                        <el-input ref="registrar" v-model="formData.registrar" :readonly="true" size="mini"
                                                  @keyup.native.enter="$_focusNext('last_date_warehouse_bill')"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="销售订单-单号:" label-width="130px">
                                    <el-input ref="bill_id_dd" v-model="formData.bill_id_dd" :readonly="true"
                                              placeholder="未关联"
                                              @keyup.native.enter="focusNext('shipper')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="7" :sm="12" :md="8">

                                <el-button style=" margin-top: 12px; margin-right: 12px;margin-left: 12px;" type="success"
                                           round @click="$router.push('/shippingAdvice')" size="mini">新建
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/shippingAdvice/lodopPrint'])" style=" margin-top: 12px; " type="primary" round
                                           @click="PrintTransferSlipInform" size="mini">打印预览
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button style=" margin-top: 12px; " type="danger" plain round
                                           :disabled="this.loading || formData.is_cancel"
                                           @click="onSubmit" size="mini">保存
                                </el-button>
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>

                            <!--<el-col class="pull-left" :lg="2">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>-->
                        </el-row>
                        <el-row hidden>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计支数:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计金额:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_amount" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总过磅重:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_weighing_heavy"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计实收:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.practical_total_price"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计佣金:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.kick_back_total"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-col>


                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" id="ShippingAdviceId" :height="(this.getViewHeight() - 200)+''" :table-column="tableColumn" :table-data-main="tableDataMain" :form-data="formData"></MnGrid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    import MnGrid from "../../components/MnGrid";
    import AddCompany from "../../components/AddCompany";
    export default {
        components: {AddCompany,MnGrid},
        name: "ShippingAdvice",
        data() {
            return {
                pageType:'开单通知书',
                filterData: {
                    customer_name:'',
                    state:"激活"
               },
                clearingFormList: [],
                otherExpensesList: [],
                start_date: '',
                isAddBill: true,
                is_fhd: false,
                loading: false,
                isDataChanage:false,
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'specification',
                        width: 210,
                        title: '规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {  field:'full_thick',
                        width: 50,
                        title: '足厚',
                        slots: {edit: 'full_thick_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'single_count_str', width: 100, title: '支数(可文本)',
                        slots: {edit: 'single_count_str_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'actual_thickness', visible: true, width: 45,
                        title: '实厚',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/
                    /*{
                        field:'single_count', width: 100, title: '数量',
                        titleHelp: {message: '支数(数量)'},
                        editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singleCountEvent}
                       }
                   },*/

                    {
                        field:'warehouse_out', visible: true,
                        width: 88,
                        title: '调出仓库',
                        slots: {edit: 'warehouse_out_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field: 'theory_weight',
                        width: 50,
                        title: '理计',
                        slots: {edit: 'theory_weight_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'remark_stock',
                        width: 320,
                        title: '产品备注', visible: true,
                        editRender: {name: 'input', props: {controls: false}}
                   },

                    {field:'total_count', visible: true, width: 50,className: 'unEditor', title: '库存'},
                    {field:'warehouse4', visible: true, width: 50,className: 'unEditor', title: '厂一'},
                    {field:'warehouse5', visible: true, width: 50,className: 'unEditor', title: '厂二'},
                    {field:'warehouse1', visible: true, width: 50,className: 'unEditor', title: '仓一'},
                    {field:'warehouse2', visible: true, width: 50,className: 'unEditor', title: '仓二'},
                    {field:'warehouse3', visible: true, width: 50,className: 'unEditor', title: '仓三'},
                    {field:'warehouse9', visible: true, width: 50,className: 'unEditor', title: '仓四'},
                    {field:'warehouse7', visible: true, width: 50,className: 'unEditor', title: '新门市'},
                    {
                        field:'single_count',visible: false, width: 100, title: '数量',
                        titleHelp: {message: '支数(数量)'},
                        slots: {edit: 'single_count_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },

                    {
                        field:'single_weight',
                        visible: false,
                        width: 50,
                        titleHelp: {message: '支重=过磅重/支数\n(kg)'},
                        title: '支重'
                   },
                    {
                        field:'warehouse_out_id',visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                   },
                    {
                        field:'price', width: 100, title: '单价', visible: false,
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价'},
                        editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.setPrice}
                       }
                   },
                    {
                        field:'single_price_practical', visible: false, width: 70, title: '实收单价', editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singlePricePracticalEvent}
                       }
                   },{
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },
                    {
                        field:'theoretical_weight_single', visible: false,
                        width: 90,
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                   },
                    {
                        field:'single_price', visible: false, width: 70, title: '每支单价',
                        titleHelp: {message: '(自动判断 单位:支)每支单价=单价'}
                   },
                    {
                        field:'tons_of_price', visible: false, width: 60, title: '吨价',
                        titleHelp: {message: '当：单位（支）\n吨价 = 产品金额 ÷ 过磅重\n当：单位（吨）\n吨价 = 单价'}
                   },
                    {
                        field:'texture', visible: false, title: '材质', width: 100,
                        editRender: {name: 'input'}
                   },
                    {
                        field:'category',
                        visible: false,
                        width: 100,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'unit',
                        width: 45,
                        title: '单位', visible: false,
                        slots: {edit: 'unit_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'weighing_heavy',
                        width: 65,
                        visible: false,
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        title: '过磅重',
                        editRender: {
                            name: 'ElInputNumber',
                            props: {controls: false},
                            events: {change: this.weighingHeavyEvent}
                       }
                   },
                    {
                        field:'amount_of_product',
                        width: 70,
                        visible: false,
                        title: '产品金额',
                        titleHelp: {message: '单位(吨) 产品金额= 过磅重*吨价\n单位(支) 产品金额= 支数*每支单价'}
                   },
                    {
                        field:'practical_price',
                        width: 70,
                        visible: false,
                        title: '实收金额',
                        titleHelp: {message: '实收金额=实收单价*支数'},
                   },
                    {field:'kick_back', width: 50, visible: false, title: '佣金', titleHelp: {message: '佣金=产品金额-实收金额'},},

                    {
                        field:'stock_bill_date_dd', visible: false,
                        title: '订单日期',
                        width: 100,
                        editRender: {name: '$input', props: {type: 'date'}}
                   },{field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}}
                ],
                tableDataMain: [],
                specification: [],
                categoryList: [],
                billingCompanyList: [],
                unitList: [],
                accountList: [],
                warehouseList: [],
                activeName: 'first',
                checked: false,
                tableData: [],
                regionList: [],
                formData: {
                    clearing_form: '欠款',
                    other_expenses : '费用',
                    other_expenses_price : '',
                    customer_phone: '',
                    shipper: '',
                    reviewer: '',
                    car_code : '',
                    bill_date: '',
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_fh: new Date(),
                    bill_date_kdtzs : '',
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id_fh : '',
                    bill_id: '',
                    settlement_days: '',
                    customer: '',
                    sales_man : '',
                    billing_company_name: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    practical_total_price : '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '发货单',
                    bill_id_type: 'FH',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
               },
           }
       },
        methods: {
            getClearingFormList(query) {
                this.$axios({
                    method: 'POST',
                    url: '/admin/clearingForm',
                    params: {
                        filterData: {pay_name: query},
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.clearingFormList = response.data.page.list
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            getOtherExpensesList(query) {
                this.$axios({
                    method: 'POST',
                    url: '/admin/otherExpenses',
                    params: {
                        filterData: {name: query},
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.otherExpensesList = response.data.page.list
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //单价设置到 每支单价、吨价
            setPrice({row}) {
                if (row.unit == '支') {
                    row.single_price = row.price
                    row.tons_of_price = ''
               } else if (row.unit == '吨') {
                    row.single_price = ''
                    row.tons_of_price = row.price
               }
                this.setSinglePricePractical({row});
                this.singleCountEvent({row});
           },
            //计算行理论支数
            getTheTheoryOfCount({row}) {
                if (row.weighing_heavy && row.theoretical_weight) {
                    row.the_theory_of_count = this.$XEUtils.round(Number(this.$XEUtils.divide(Number(row.weighing_heavy), Number(row.theoretical_weight))),1)
                    if (isNaN(row.the_theory_of_count )) {
                        row.the_theory_of_count = null;
                   }
               } else {
                    row.the_theory_of_count = null;
               }
           },
            //计算行 理论重量 计算 理论支重
            getTheTheoryOfCountEvent({row}) {
                //console.log(`${column.title} 触发 change 事件`)
                //console.log(`getTheTheoryOfCountEvent 触发 change 事件` + row.specification + "   :  " + row.single_count)
                //初始化
                row.theoretical_weight = Number(0);
                row.theoretical_weight_single_count = Number(0);
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    const wallThickness = Number(s[2]);
                    const lengthExtent = Number(s[3].slice(0, s[3].indexOf("米") + 1).replace("米", ""));
                    const theoretical_weight = (((sideA + sideB) * 2 - 4 * wallThickness) * wallThickness * 0.00785 * lengthExtent) / 1000;
                    row.theoretical_weight = this.$XEUtils.round(theoretical_weight,6);
                    if (row.single_count != null) {
                        row.theoretical_weight_single_count = this.$XEUtils.round(this.$XEUtils.multiply(theoretical_weight , Number(row.single_count)),6);
                   }
                    //row.unit == '支' &&
                    if (row.single_count != null) {
                        //过磅重
                        row.weighing_heavy = row.theoretical_weight_single_count
                   }

               }
                //console.log(row)
                //console.log(column)
           },
            //合计整个表格的理论重量
            sumTheoreticalWeight() {
                //const $grid = this.$refs.tableDataMainXGrid
                let theoreticalWeightAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log(this.tableDataMain[t].theoretical_weight);
                    if (this.tableDataMain[t].theoretical_weight_single_count != null) {
                        theoreticalWeightAll = this.NumberAdd(theoreticalWeightAll, Number(this.tableDataMain[t].theoretical_weight_single_count));
                   }
               }
                this.formData.total_theoretical_weight = this.$XEUtils.round(Number(theoreticalWeightAll),6);
                //console.log(theoreticalWeightAll);
           },
            //计算行产品金额
            getAmountOfProductEvent({row}) {
                //console.log(`getAmountOfProductEvent触发 change 事件`)
                row.amount_of_product = Number(0);
                if (row.unit != null && ((row.tons_of_price != null && row.weighing_heavy != null) || (row.single_price != null && row.single_count != null))) {
                    if (row.unit == '吨' && row.tons_of_price != null && row.weighing_heavy != null) {
                        row.amount_of_product = this.$XEUtils.round(this.$XEUtils.multiply(row.tons_of_price, row.weighing_heavy),2);
                        //row.amount_of_product = Number(row.tons_of_price * row.weighing_heavy).toFixed(2);
                   } else if (row.unit == '支' && row.single_price != null && row.single_count != null) {
                        //row.amount_of_product = Number(row.single_price * row.single_count).toFixed(2);
                        row.amount_of_product = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price, row.single_count),2);
                   } else {
                        row.amount_of_product = '';
                   }
               }
           },
            //计算行实际金额
            getPracticalPriceEvent({row}) {
                //console.log(`getAmountOfProductEvent触发 change 事件`)
                row.practical_price = Number(0);
                if (row.practical_price != null && row.single_count != null) {
                    if (row.single_price_practical != null && row.single_count != null) {
                        if (row.unit == '支') {
                            row.practical_price = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price_practical, row.single_count),2);
                       } else if (row.unit == '吨') {
                            row.practical_price = this.$XEUtils.round(this.$XEUtils.multiply(row.single_price_practical, row.weighing_heavy),2);
                       }
                   } else {
                        row.practical_price = '';
                   }
               }
           },
            //合计产品金额
            sumAmountOfProduct() {
                var amountOfProductAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log("this.tableDataMain[t].amount_of_product");
                    //console.log(this.tableDataMain[t].amount_of_product);
                    if (this.tableDataMain[t].amount_of_product != null) {
                        amountOfProductAll = this.$XEUtils.add(amountOfProductAll,Number(this.tableDataMain[t].amount_of_product));
                   }
               }
                this.formData.total_amount = this.$XEUtils.round(amountOfProductAll,2);
                //console.log("合计金额：" + this.formData.amount_of_product)
           },
            //合计实收金额
            sumPracticalTotalPrice() {
                let practicalTotalPriceAll = Number(0);
                for (let t in this.tableDataMain) {
                    //console.log("this.tableDataMain[t].practical_price");
                    //console.log(this.tableDataMain[t].practical_price);
                    if (this.tableDataMain[t].practical_price != null) {
                        practicalTotalPriceAll = this.NumberAdd(practicalTotalPriceAll, Number(this.tableDataMain[t].practical_price));
                   }
               }
                this.formData.practical_total_price = this.$XEUtils.round(Number(practicalTotalPriceAll),2);
                //console.log("合计金额：" + this.formData.amount_of_product)
           },
            //合计 佣金
            sumKickBackTotal() {
                let kickBackTotal = Number(0);
                for (let t in this.tableDataMain) {
                    if (this.tableDataMain[t].kick_back != null) {
                        kickBackTotal = this.NumberAdd(kickBackTotal, Number(this.tableDataMain[t].kick_back));
                   }
               }
                this.formData.kick_back_total = this.$XEUtils.round(Number(kickBackTotal),2);
                //console.log("合计金额：" + this.formData.amount_of_product)
           },
            //计算行 支重
            getSingleWeight({row}) {
                row.single_weight = '';
                if (row.single_count != null && row.weighing_heavy != null) {
                    row.single_weight = this.$XEUtils.round(Number(this.$XEUtils.divide(this.$XEUtils.multiply(row.weighing_heavy , 1000),Number(row.single_count))),2);
               }
           },
            //计算行 佣金
            getKickBack({row}) {
                row.kick_back = '';
                if (row.amount_of_product != null && row.practical_price != null) {
                    row.kick_back = this.$XEUtils.round(Number(this.$XEUtils.subtract(Number(row.amount_of_product), Number(row.practical_price))),2);
               }
           },
            //合计支数
            sumSingleCount() {
                var singleCountAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log(this.tableDataMain[t].theoretical_weight);
                    if (this.tableDataMain[t].single_count != null) {
                        singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                   }
               }
                this.formData.total_number = singleCountAll;
           },
            //执行
            singlePricePracticalEvent({row}) {
                //计算行 实际金额
                this.getPracticalPriceEvent({row});
                //计算行 佣金
                this.getKickBack({row});
           },
            singleCountEvent({row}) {
                //计算行理论重量
                this.getTheTheoryOfCountEvent({row});
                //计算行产品金额
                this.getAmountOfProductEvent({row});
                //计算行 支重
                this.getSingleWeight({row});
                //计算行 实际金额
                this.getPracticalPriceEvent({row});
                //计算行 佣金
                this.getKickBack({row});
                //合计支数
                this.sumSingleCount();
                //合计整个表格的理论重量
                this.sumTheoreticalWeight();
                //合计产品金额
                this.sumAmountOfProduct();
                //合计实收金额
                this.sumPracticalTotalPrice();
                //合计佣金
                this.sumKickBackTotal();
           },
            weighingHeavyEvent({row}) {
                //计算行理论重量
                //this.getTheTheoryOfCountEvent({row});
                //计算行产品金额
                this.getAmountOfProductEvent({row});
                //计算行 支重
                this.getSingleWeight({row});
                //计算行 实际金额
                this.getPracticalPriceEvent({row});
                //计算行 佣金
                this.getKickBack({row});
                //合计支数
                this.sumSingleCount();
                //合计整个表格的理论重量
                this.sumTheoreticalWeight();
                //合计产品金额
                this.sumAmountOfProduct();
                //合计实收金额
                this.sumPracticalTotalPrice();
                //合计佣金
                this.sumKickBackTotal();
           },
            //实收单价
            setSinglePricePractical({row}) {
                if (row.unit == '支') {
                    row.single_price_practical = row.singlePrice
               } else if (row.unit == '吨') {
                    row.single_price_practical = row.tons_of_price;
               } else {
                    row.single_price_practical = ''
               }
           },
            //开单公司名
            /*getbillingCompanySettingList(query) {
                this.$axios({
                    method: 'POST',
                    url: '/admin/billingCompanySetting/search',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.billingCompanyList = response.data.page.list
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },*/
            //查询类别
            searcCategoryList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/categorySetting/search',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.categoryList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //查询类别
            searchCategoryList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/categorySetting/search',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.categoryList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            /**
             * 选中规格后执行对应的操作
             * @param row
             * 扩展继续查询该规格历史交易记录、进货记录、历史报价、调仓信息
             */
            autocompleteSelect({row}) {
                // this.getTheTheoryOfCountEvent({row});
                this.singleCountEvent({row});
                //自动设置类别,根据前面两个值来对比判断
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    if (sideA == sideB) {
                        row.category = '方管'
                   } else {
                        row.category = '矩管'
                   }
               }
           },
            //查询账户
            searchAccountList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountList',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.accountList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //查询规格
            searchUnitList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/unitSetting/search',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.unitList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            sumNum(list, field) {
                let count = 0
                list.forEach(item => {
                    count = this.NumberAdd(count, Number(item[field]));
               })
                //console.log("count合计: " + count);
                return this.$XEUtils.commafy(count)
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'price') {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            //查询实际规格
            specificationSelect(val, {row, rowIndex}) {
                row.specification = val.specification_name;
                //自动设置类别,根据前面两个值来对比判断
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    if (sideA == sideB) {
                        row.category = '方管'
                   } else {
                        row.category = '矩管'
                   }
               }
                //查询存货
                this.searchWarehouseStockBySpecificationName({row, rowIndex});
                this.singleCountEvent({row});
           },

            searchWarehouseStockBySpecificationName({row, rowIndex}) {
                //先清空行中的所有仓库库存支数

                for (var i in this.tableDataMain[rowIndex]) {
                    //console.log("先清空行中的所有仓库库存支数: ");
                    //let name = i.replaceAll("warehouse", "").trim();
                    let name = i.replace("warehouse", "").trim();
                    if (this.isNumberVue(Number(name))) {
                        this.tableDataMain[rowIndex][i] = 0;
                   }
               }
                this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchWarehouseStockBySpecificationName',
                    data: {
                        specification: row.specification,
                        category: row.category
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    //console.log(response.data)       //请求成功返回的数据
                    if (response.status === 200) {
                        //row = {...row, ...response.data}
                        for (var st in response.data) {
                            this.tableDataMain[rowIndex][st] = response.data[st];
                       }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //查询仓库库存
            searchWarehouseStock(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/specification/searchByTotalSingleCount',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200) {
                        this.specification = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.loading = true
                this.$axios({
                    method: 'post',
                    url: '/admin/shippingAdvice/search',
                    params: {
                        bill_id_fh : this.formData.bill_id_fh,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    this.loading = false
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           }, searchWarehouseBillByKdTzs() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlipInform/search',
                    params: {
                        bill_id_kdtzs : this.formData.bill_id_kdtzs,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.showCustomerEdit = false;
                        this.isAddBill = true;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        //遍历处理 清空 stock id 用于 销售开单保存
                        for (let i = 0; i < this.tableDataMain.length; i++) {
                            this.tableDataMain[i].id = '';
                            this.tableDataMain[i].batch_number = '';
                       }
                        //清空bill Id
                        this.formData.id = '';
                        /*if((this.formData.bill_id_fh == null || this.formData.bill_id_fh == '') && (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '')){
                            this.formData.remark_warehouse_bill = this.formData.taxTateWarehouseBill
                       }*/
                        //税率取消关联赋值
                        this.formData.tax_tate_warehouse_bill = '';
                        //开单日期今天
                        this.formData.bill_date_fh = new Date();
                        //记录员
                        this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            PrintTransferSlipInform() {
                if (this.formData.bill_id_fh != null && this.formData.bill_id_fh != '') {
                    if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                        this.$message({
                            showClose: true,
                            message: '当前数据已被修改，请先保存，再打印！',
                            type: 'error'
                       });
                   } else {
                        this.$router.push({path: "/printShippingAdvice", query: {bill_id_fh : this.formData.bill_id_fh}})
                   }
               } else {
                    this.$message({
                        showClose: true,
                        message: '打印前，请先保存单据，单号不能为空！',
                        type: 'error'
                   });
               }
           },
            getUpdateEvent() {
                let updateRecords = this.$refs.xGrid.getUpdateRecords()
                return updateRecords.length
           },
            onSubmit() {
                this.loading = true
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.isDataChanage = false;
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/shippingAdvice/saveVue';
               } else {
                    url = '/admin/shippingAdvice/updateVue';
               }
                this.formData.only_bill_date = this.formData.bill_date_fh
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    this.loading = false
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.$router.push({
                            path: "/shippingAdvice",
                            query: {bill_id_fh : response.data.warehouseBill.bill_id_fh}
                       })
                        this.showCustomerEdit = false;
                        this.isAddBill = false;
                        this.isDataChanage = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                   } else {
                        this.loading = false
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    this.loading = false
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           }, focusNext(nextRef) {
                this.$refs[nextRef].focus()
           },
            blurNext(thisRef) {
                this.$refs[thisRef].blur()
           },
            //销售开单 生成 发货单
            searchWarehouseBillByKd() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.loading = true;
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlip/search',
                    params: {
                        bill_id_kd : this.formData.bill_id_kd,
                        is_fhd:this.is_fhd
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    this.loading = false;
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        //遍历处理 清空 stock id 用于 销售开单保存
                        for (let i = 0; i < this.tableDataMain.length; i++) {
                            this.tableDataMain[i].id = '';
                            this.tableDataMain[i].batch_number = '';
                            this.tableDataMain[i].single_count_str = this.tableDataMain[i].single_count;
                       }
                        //清空bill Id
                        this.formData.id = '';
                        /*if((this.formData.bill_id_fh == null || this.formData.bill_id_fh == '') && (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '')){
                            this.formData.remark_warehouse_bill = this.formData.taxTateWarehouseBill
                       }*/
                        //税率取消关联赋值
                        this.formData.tax_tate_warehouse_bill = '';
                        //开单日期今天
                        this.formData.bill_date_fh = new Date();
                        //记录员
                        this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
               });
           },
       }, computed: {
            // 计算属性的 getter
            billName: function () {
                // `this` 指向 vm 实例
                if (this.formData.clearing_form === '欠款') {
                    return '欠款单';
               } else {
                    return '调拨单';
               }
           }
       },
        created() {
            this.formData.bill_id_kdtzs = this.$route.query.bill_id_kdtzs;
            this.formData.bill_id_fh = this.$route.query.bill_id_fh;
            this.formData.bill_id_kd = this.$route.query.bill_id_kd;
            this.is_fhd = this.$route.query.is_fhd;
            this.$_searchCustomerList('');
            this.$_searcWarehouseList('');
            this.searchCategoryList('');
            this.searchUnitList('');
            this.searchAccountList('');
            //this.getbillingCompanySettingList('');
            this.getClearingFormList('');
            this.getOtherExpensesList('');
            this.$_getAccountListByRoleId('10');//业务员
            if (this.formData.bill_id_fh != null && this.formData.bill_id_fh != '') {
                this.searchWarehouseBill();
           } else if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                this.searchWarehouseBillByKdTzs();
           } else if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                this.searchWarehouseBillByKd();
           } else {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
           }
            //如果记录员(业务员)为空，则自动填写当前账户名字
            if (this.formData.registrar == null || this.formData.registrar == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
           }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend {
        width: 80px !important;
   }

    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 128px !important;
   }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
   }

    /deep/ .vxe-input--number-suffix {
        display: none;
   }


    /deep/ .vxe-input--suffix {
        height: auto;
   }

    /deep/ .vxe-input .vxe-input--suffix {
        width: auto;
   }

    /deep/ .vxe-input.is--controls.type--number .vxe-input--suffix {
        right: 0.6em;
   }

</style>
