<template>
    <vxe-modal width="780px" :title="isAddCustomer ?  '新增客户信息' : '编辑客户信息'"
               v-model="showCustomerEdit"
               :esc-closable="true"
               :lockView="false" :mask="false">
        <el-form ref="customersForm" :rules="pageType=='销售开单'?customersFormRulesKD:customersFormRules"
                 :model="customersForm" label-width="100px">
            <el-row>
                <el-col :lg="12" :md="12">
                    <el-form-item label="客户名称" prop="customer_name">
                        <el-input v-model="customersForm.customer_name" clearable size="mini"
                                  style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="所属业务" prop="sales_man">
                        <el-select style="width: 250px"
                                   size="mini"
                                   v-model="customersForm.sales_man"
                                   default-first-option
                                   clearable
                                   filterable
                                   placeholder="可搜索查询">
                            <el-option
                                    v-for="item in $store.getters.getSalesManList"
                                    :key="item.id"
                                    :label="item.nick_name"
                                    :value="item.nick_name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>


                <el-col :lg="12" :md="12" v-if="isAddCustomer || $_getActionKeyListBoolean(['/customers/hasSeeCompanyPhone'])" >
                    <el-form-item label="联系人" prop="linkman">
                        <el-input v-model="customersForm.linkman" clearable size="mini" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12" v-if="isAddCustomer || $_getActionKeyListBoolean(['/customers/hasSeeCompanyPhone'])" >
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="customersForm.phone" clearable size="mini" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :lg="24" :md="24" v-if="isAddCustomer || $_getActionKeyListBoolean(['/customers/hasSeeCompanyPhone'])" >
                    <el-form-item label="地址" prop="address">
                        <el-input v-model="customersForm.address" clearable size="mini" style="width: 630px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="24" :md="24">
                    <el-form-item label="曾用名" prop="usedName">
                        <el-input v-model="customersForm.used_name" clearable readonly size="mini"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="法人代码" prop="certificateCode">
                        <el-input v-model="customersForm.certificate_code" clearable size="mini"
                                  style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="法人电话">
                        <el-input v-model="customersForm.certificate_phone" clearable size="mini"
                                  style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :lg="12" :md="12">
                    <el-form-item label="开户银行">
                        <el-input v-model="customersForm.deposit_bank" clearable size="mini"
                                  style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :lg="12" :md="12">
                    <el-form-item label="税务登记号">
                        <el-input v-model="customersForm.tax_id" clearable size="mini" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="信用额度" prop="creditLines">
                        <el-input-number :controls="false" :precision="2" v-model.number="customersForm.credit_lines"
                                         clearable size="mini" style="width: 250px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="状态">
                        <el-switch active-value="激活" inactive-value="禁用" v-model="customersForm.state"></el-switch>
                    </el-form-item>
                </el-col>

                <el-col :lg="12" :md="12">
                    <el-form-item label="账期" prop="paymentDays">
                        <el-input type="number" v-model.number="customersForm.payment_days" size="mini">
                            <template slot="append">天</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="月结日期：每月" label-width="120px" prop="monthlyStatementDate">
                        <el-input type="number" v-model.number="customersForm.monthly_statement_date" size="mini"
                                  style="width: 230px">
                            <template slot="append">号(零代表当月月末)</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="customersForm.remark" size="mini" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="经销期限">
                        <el-date-picker
                                v-model="customersForm.period_distribution"
                                size="mini"
                                type="date"
                                style="width: 250px"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="月任务" prop="monthTask">
                        <el-input-number :controls="false" :precision="2" v-model.number="customersForm.month_task"
                                         size="mini" style="width: 250px"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :lg="12" :md="12">
                    <el-form-item label="年任务" prop="yearTask">
                        <el-input-number :controls="false" :precision="2" v-model.number="customersForm.year_task"
                                         size="mini" style="width: 250px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
            <el-upload
                    ref="elUpload"
                    action="#"
                    list-type="picture-card"
                    :file-list="file_img_list"
                    :auto-upload="true"
                    :http-request="uploadFile"
                    :on-remove="handleRemove"
                    accept=".jpg,.jpeg,.png">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <!--大图预览-->
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
            </el-upload>
        <!--图片预览-->
        <!--
        draggable
        是否启用窗口拖动
        lock-view
        是否锁住页面，不允许窗口之外的任何操作
        lock-scroll
        是否锁住滚动条，不允许页面滚动
        mask
        是否显示遮罩层
        mask-closable
        是否允许点击遮罩层关闭窗口
        esc-closable
        是否允许按 Esc 键关闭窗口
        resize
        是否允许窗口边缘拖动调整窗口大小-->
        <vxe-modal title="资料图片" width="500px" v-model="dialogVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-image style="width: 100%;" :src="dialogImageUrl"/>
        </vxe-modal>
        <div align="center">
            <el-button size="mini" @click="showCustomerEdit = false">取 消</el-button>
            <el-button size="mini" type="primary" :disabled="loading" @click="addRoUpdateCustomerEditEvent">确 定</el-button>
        </div>
    </vxe-modal>
</template>

<script>

    export default {
        name: "AddCompany",
        props: {
            'pageType': {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                //fileList: [{name: 'lineage2.jpg', url: 'https://www.l2qq.com/img/lineage2.jpg'}],
                //showViewer: false, // 显示查看器
                loading:false,
                //图片上传
                dialogImageUrl: '',
                dialogVisible: false,
                bigImgVisible: false,
                disabled: false,
                //图片上传end
                showCustomerEdit: false,
                isAddCustomer: false,
                account: [],
                customersFormRules: {
                    sales_man: [
                        {required: true, message: '请输选择业务员', trigger: 'blur'},
                    ],
                    customer_name: [
                        {required: true, message: '请选输入客户名称', trigger: 'blur'}
                    ],
                    certificate_code: [
                        {required: false, message: '请选输入法人代码', trigger: 'blur'}
                    ],
                    linkman_phone: [
                        {required: false, message: '请选输入联系人电话', trigger: 'blur'}
                    ],
                    area: [
                        {required: false, message: '请选输入地区', trigger: 'blur'}
                    ],
                    remark: [
                        {required: false, message: '请选输入备注', trigger: 'blur'}
                    ],
                    month_task: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    year_task: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    payment_days: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    monthly_statement_date: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    credit_lines: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                },
                customersFormRulesKD: {
                    sales_man: [
                        {required: true, message: '请输选择业务员', trigger: 'blur'},
                    ],
                    linkman: [
                        {required: true, message: '请选输入联系人', trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请选输入地址', trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, message: '请选输入联系电话', trigger: 'blur'}
                    ],
                    customer_name: [
                        {required: true, message: '请选输入客户名称', trigger: 'blur'}
                    ],
                    certificate_code: [
                        {required: false, message: '请选输入法人代码', trigger: 'blur'}
                    ],
                    linkman_phone: [
                        {required: false, message: '请选输入联系人电话', trigger: 'blur'}
                    ],
                    area: [
                        {required: false, message: '请选输入地区', trigger: 'blur'}
                    ],
                    remark: [
                        {required: false, message: '请选输入备注', trigger: 'blur'}
                    ],
                    month_task: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    year_task: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    payment_days: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    monthly_statement_date: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                    credit_lines: [
                        {required: false, message: '只能输入数字', trigger: 'blur', type: 'number'}
                    ],
                },
                file_img_list:[],
                customersForm: {
                    img_list:'',
                    id: '',
                    sales_man: '',
                    year_task: 0,
                    period_distribution: '',
                    credit_lines: '',
                    sales_territory: 0,
                    month_task: 0,
                    customer_name: '',
                    certificate_code: '',
                    linkman: '',
                    linkman_phone: '',
                    certificate_phone: '',
                    deposit_bank: '',
                    phone: '',
                    tax_id: '',
                    area: '',
                    remark: '',
                    address: '',
                    used_name: '',
                    state: '激活',
                    payment_days: 0,
                    monthly_statement_date: 0,
                },
            }
        },

        created() {
            //this.accountEvent();
            //根据回显的图片路径通过,逗号拼接的图片路径字符串，来实现图片的回显，则可以将图片路径字符串转化为一个fileList格式的数组才可以。
        },
        methods: {
            uploadFile(params){
                const file = params.file
                this.$refs.customersForm.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        //console.log('uploadFile params',params)
                        if (this.beforeUpload(file)){
                            // 根据后台需求数据格式
                            const form = new FormData();
                            // 文件对象
                            form.append("file", file);
                            form.append("uid", file.uid);
                            form.append("customersForm", JSON.stringify(this.customersForm));
                            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
                            //form.append("enctype", 'multipart/form-data');//enctype="multipart/form-data"
                            // 项目封装的请求方法，下面做简单介绍
                            this.$axios({
                                method: 'POST',
                                url: '/admin/upload/img',
                                //headers: {"content-type": "multipart/form-data"},
                                data: form
                            }).then((response) => {         //这里使用了ES6的语法
                                //console.log(response)
                                this.loading = false;
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message.success('上传图片成功!')
                                    //const cImgList = this.file_img_list;
                                    this.customersForm = response.data.customer;
                                    //this.file_img_list = cImgList;
                                    this.isAddCustomer = false;
                                    //console.log('file',file)
                                    /*var createFileStr = {
                                        name: file.name,
                                        url:'https://www.l2qq.com/img/lineage2.jpg',
                                    };*/
                                    //this.file_img_list.push(createFileStr);
                                    //this.file_img_list =  [{name: 'food.jpg', url: 'https://www.l2qq.com/img/lineage2.jpg'}];
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                    });
                                    //上传失败，直接移除
                                    this.handleRemove(file)
                                    return
                                }
                            }).catch((error) => {
                                this.loading = false;
                                console.log(error)
                                //上传失败，直接移除
                                this.handleRemove(file)
                            });
                        }
                    } else {
                        //console.log('error submit!!');
                        this.$message({
                            showClose: true,
                            message: '请先填写完客户相关资料并保存。',
                            type: 'error'
                        });
                        let uploadFiles = this.$refs['elUpload'].uploadFiles;
                        //console.log('uploadFiles',uploadFiles)
                        uploadFiles.forEach((value, index) => {
                            //console.log(value.uid);
                            if (value.uid === file.uid) {
                                uploadFiles.splice(index, 1);
                                this.file_img_list.splice(index, 1);
                            }
                        })
                        return false;
                    }
                });

            },
            // 关闭
            closeViewer() {
                this.showViewer = false;
            },
            // 上传之前的回调
            beforeUpload(file) {
                const fileType = file.type
                const isJPG = fileType === 'image/jpg' || fileType === 'image/jpeg' || fileType === 'image/png'
                const isLt5M = file.size / 1024 / 1024 < 5
                //const isBlank = !this.isBlankVue(this.customersForm.customer_name)
                if (!isJPG) {
                    this.$message.error('上传图片的格式只能是 JPG或PNG 格式!')
                }
                /*if (!isBlank) {
                    this.$message.error('请先输入客户名称!')
                    this.handleRemove(file);
                }*/
                if (!isLt5M) {
                    this.$message.error('上传图片的大小不能超过 5M!')
                }
                //return isJPG && isLt5M && isBlank
                return isJPG && isLt5M
            },
            //图片的删除方法 , fileList
            handleRemove(file) {
                //注意图片删除时，会改变图片的Url格式，但是不影响具体的使用。
                //this.file_img_list = fileList;
                //console.log('handleRemove',file)
                //通过删除成功后，再进行对应的移除
                this.$axios({
                    method: 'POST',
                    url: '/admin/upload/deleteImg',
                    data: {
                        url: file.url,
                        uid: file.uid,
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message.success('图片删除成功！');
                        //再查一遍
                        this.editRowEvent(this.customersForm.id);
                        /*let uploadFiles = this.$refs['elUpload'].uploadFiles;
                        console.log('uploadFiles',uploadFiles)
                        uploadFiles.forEach((value, index) => {
                            console.log(value.uid);
                            if (value.uid === file.uid) {
                                uploadFiles.splice(index, 1);
                                this.file_img_list.splice(index, 1);
                            }
                        })*/
                    }else{
                        this.$message.error('图片删除失败！');
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            //大图预览
            handlePictureCardPreview(file) {
                //console.log('handlePictureCardPreview',file)
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            // 下载  imgage图片blob类型下载
            handleDownload(file) {
                let a = document.createElement('a');
                a.download = file.name;
                a.href = (file.url);
                a.click();
            },
            // select 添加下拉 添加商品标签
            showShipTemplate() {
                /*this.$prompt("请输入新的商品标签", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
               }).then(({ value}) => {
                    this.labelslist.push({ code: this.labelslist.length, value: value});
               });*/
                this.addEvent()
                //this.$refs.AddCompany.addEvent()
            },
            // select 添加下拉
            visibleChange(visible, refName) {
                if (visible) {
                    const ref = refName;
                    //const ref = this.$refs[refName];
                    let popper = ref.$refs.popper;
                    if (popper.$el) popper = popper.$el;
                    if (
                        !Array.from(popper.children).some(
                            (v) => v.className === "el-template-menu__list"
                        )
                    ) {
                        const el = document.createElement("ul");
                        el.className = "el-template-menu__list";
                        el.style =
                            "border-top:2px solid rgb(219 225 241); padding:0; color:rgb(64 158 255);font-size: 13px";
                        el.innerHTML = `<li class="el-cascader-node text-center" style="height:37px;line-height: 50px;margin-left:10px;">
            <span class="el-cascader-node__label"><i class="font-blue el-icon-plus"></i> 添加新增</span>
            </li>`;
                        popper.appendChild(el);
                        el.onclick = () => {
                            refName.blur();//该对象隐藏下拉框
                            this.showShipTemplate(null, false);
                        };
                    }
                }
            },
            addEvent() {
                this.showCustomerEdit = true
                this.isAddCustomer = true
                this.file_img_list = []
                this.customersForm.customer_name = '';
                this.customersForm.sales_man = '';
                this.customersForm.linkman = '';
                this.customersForm.phone = '';
                this.customersForm.id = '';

                this.customersForm.address = '';

                this.customersForm.certificate_code = '';
                this.customersForm.area = '';
                this.customersForm.remark = '';
                this.customersForm.state = '激活';

                this.customersForm.certificate_phone = '';
                this.customersForm.month_task = 0;
                this.customersForm.year_task = 0;
                this.customersForm.deposit_bank = '';
                this.customersForm.tax_id = '';
                this.customersForm.linkman_phone = '';
                this.customersForm.period_distribution = '';
                this.customersForm.credit_lines = '';
                this.customersForm.payment_days = 0;
                this.customersForm.monthly_statement_date = 0;
                this.customersForm.used_name = '';
            },
            editRowEvent(id) {
                this.showCustomerEdit = true;
                this.isAddCustomer = false;
                this.$axios({
                    method: 'post',
                    url: '/admin/customers/editVue',
                    params: {
                        id: id
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.customersForm = response.data;
                        this.file_img_list = JSON.parse(this.customersForm.img_list);//将json字符串转换成json对象
                        /*this.customersForm.customer_name = response.data.customer_name;
                        this.customersForm.sales_man = response.data.sales_man;
                        this.customersForm.linkman = response.data.linkman;
                        this.customersForm.phone = response.data.phone;
                        this.customersForm.id = response.data.id;
                        this.customersForm.certificate_code = response.data.certificate_code;
                        this.customersForm.certificate_phone = response.data.certificate_phone;
                        this.customersForm.area = response.data.area;
                        this.customersForm.remark = response.data.remark;
                        this.customersForm.state = response.data.state;
                        this.customersForm.month_task = response.data.month_task;
                        this.customersForm.year_task = response.data.year_task;
                        this.customersForm.deposit_bank = response.data.deposit_bank;
                        this.customersForm.tax_id = response.data.tax_id;
                        this.customersForm.linkman_phone = response.data.linkman_phone;
                        this.customersForm.period_distribution = response.data.period_distribution;
                        this.customersForm.credit_lines = response.data.credit_lines;
                        this.customersForm.payment_days = response.data.payment_days;
                        this.customersForm.monthly_statement_date = response.data.monthly_statement_date;
                        this.customersForm.address = response.data.address;
                        this.customersForm.used_name = response.data.used_name;*/
                    }

                }).catch((error) => {
                    console.log(error)
                });
            },
            addRoUpdateCustomerEditEvent() {
                this.loading = true;
                let url = '';
                if (this.isAddCustomer) {
                    url = '/admin/customers/saveVue';
                } else {
                    url = '/admin/customers/updateVue';
                }
                this.$refs.customersForm.validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.customersForm,
                        }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            this.loading = false;
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.showCustomerEdit = false;
                                //this.$data._customerList = [];
                                this.$_searchCustomerList();
                                if (this.pageType == '客户信息') {
                                    this.$parent.getCustomerList();
                                }
                                //this.getCustomerList();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            this.loading = false;
                            console.log(error)
                        });
                    } else {
                        this.loading = false;
                        //console.log('error submit!!');
                        return false;
                    }
                });
            },
            accountEvent(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountListByName',
                    params: {
                        keyword: query
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.account = response.data.page.list
                        this.$emit('childByAccountEvent', response.data.page.list)
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
